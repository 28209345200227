import Vue from 'vue'
import vant from 'vant'
import 'vant/lib/index.css'
import 'amfe-flexible'
import VueDOMPurifyHTML from 'vue-dompurify-html';

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.ignoredElements = ['wx-open-launch-weapp'];
Vue.config.productionTip = false

Vue.use(vant);
import { Toast } from 'vant-green';

Vue.use(Toast);
Vue.use(VueDOMPurifyHTML, {
  default: { USE_PROFILES: { html: true }, ALLOWED_ATTR: ['style', 'align'] }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
