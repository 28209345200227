import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import wxAuth from '../views/wxAuth.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'index',
    component: Index
  },
  {
    path: '/wxAuth',
    name: 'wxAuth',
    component: wxAuth
  },
  {
    path: '/AboutView',
    name: 'AboutView',
     component: () => import('../views/AboutView.vue')
  },
   {
    path: '/map',
    name: 'map',
     component: () => import('../views/map/map.vue')
  },
   {
    path: '/ScanCode',
    name: 'ScanCode',
     component: () => import('../views/map/ScanCode.vue')
  },
   {
    path: '/location',
    name: 'location',
     component: () => import('../views/map/location.vue')
  },
     {
    path: '/customer',
    name: 'customer',
     component: () => import('../views/map/customer.vue')
  },
  {
    path: '/Area',
    name: 'Area',
     component: () => import('../views/map/Area.vue')
  },
   {
    path: '/mapWchet',
    name: 'mapWchet',
     component: () => import('../views/mapWchet.vue')
  },
  {
    path: '/tickets',
    name: 'tickets',
     component: () => import('../views/tickets/tickets.vue')
  },
  {
    path: '/sellTickets',
    name: 'sellTickets',
     component: () => import('../views/tickets/sellTickets.vue')
  },
  {
    path: '/coupon',
    name: 'coupon',
     component: () => import('../views/tickets/coupon.vue')
  },
  {
    path: '/newIndex',
    name: 'newIndex',
     component: () => import('../views/newIndex.vue')
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
})

export default router
