import axios from 'axios';

/**
 * @name: xxx
 * @Date: 2021-04-30 10:00:00
 * @msg:  微信分享朋友，分享朋友圈，H5跳转小程序配置
 * @param {object} pageInfo
 * @return {*}
 */
const wxConfig = (pageInfo) => {
  console.log(window.wx)
  // let ua = navigator.userAgent.toLowerCase();
  // let isWXWork = ua.match(/wxwork/i) == 'wxwork'; // 企业微信
  // let isWeixin = !isWXWork && ua.match(/MicroMessenger/i) == 'micromessenger'; // 微信
  // if (!isWeixin) {
  //   return;
  // }
  axios({
    method: 'get',
    url: `https://changflowapi.liyuekj.top/flow/wechatofficalaccount/wechatOfficalAccount/noauth/wechatJsSdkConfig?url=${window.location.href}`,
    // data: {
    //     JsSdkUrl: window.location.href
    // },
    // transformRequest: [function (data) {
    //     let ret = '';
    //     for (let it in data) {
    //         ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
    //     }
    //     return ret;
    // }],
    // headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded'
    // }
  }).then(function (res) {
    if (res.data) {
      let data = res.data.result;
      console.log(data)
      window.wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的JS接口列表
        openTagList: ['wx-open-launch-weapp', 'wx-open-launch-app', 'wx-open-subscribe', 'wx-open-audio']
      });
      window.wx.ready(function () {
        console.log('进入ready')
        // window.wx.updateAppMessageShareData({ // 朋友
        //   title: pageInfo.shareTitle, // 分享标题
        //   desc: pageInfo.shareDesc, // 分享描述
        //   link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        //   imgUrl: pageInfo.shareImg, // 分享图标
        //   success: function () {
        //     // 用户点击了分享后执行的回调函数
        //     console.log('updateAppMessageShareData success');
        //   }
        // });
        // window.wx.updateTimelineShareData({ // 分享朋友圈
        //   title: pageInfo.shareTitle, // 分享标题
        //   link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        //   imgUrl: pageInfo.shareImg, // 分享图标
        //   success: function () {
        //     // 设置成功
        //     console.log('updateTimelineShareData success');
        //   }
        // })
      });
      wx.error(function (res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，
        // 也可以在返回的res参数中查看，对于SPA可以在这里更新签名
        console.error('wx.config.error' + JSON.stringify(res));
      });
      window.wx.checkJsApi({
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: function (res) {
          console.log('checkJsApi', res)
        }
      });
    }
  });
};

export {
  wxConfig
}
