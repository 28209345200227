<template>
	<div>
		<div class="container-header-icon" v-if="loadingkey" @touchmove.prevent @mousewheel.prevent>
			<img src="../assets/Washing/loading.gif" alt="" class="header-icon-loading" />
		</div>
		<div class="leaflet_box">
			<div id="map" class="map"></div>
			<!-- 头部 -->
			<div class="header-box-up">
				<div class="header-box">
					<img src="../assets/map/left.png" alt="" class="left_icon" @click="handleReturn" v-if="leftflag" />
					<div class="SwitchFlag_demo" @click="TitleplaybackPlay">
						<span class="title-name">{{ name }}</span>
						<div style="font-size: 0">
							<img src="../assets/map/up.png" class="down_icon" v-if="SwitchFlag" />
							<img src="../assets/map/down.png" class="down_icon" v-else />
						</div>
					</div>
				</div>
			</div>
			<!-- 标题弹框 FirstLagShow -->
			<div class="Title_box" v-if="FirstLagShow">
				<div class="Titleplayback_box_main">
					<div class="Titleplayback">
						<div class="Titleplayback_left" @click="FirstLagPlay">
							<img :src="coverImg" alt="" class="Titleplayback_icon spin"
								:class="{ 'spin-active': isSpinning }" />
							<div class="map_start_play">
								<img src="../assets/map/play.png" alt="" class="start-icon" v-if="PlaybackFlag" />
								<img src="../assets/map/start.png" alt="" class="start-icon" v-else />
							</div>
						</div>
						<div class="Titleplayback_right">
							<div style="display: flex; align-items: center">
								<span class="right-name">{{ name }}</span>
								<span class="right-leve" v-if="level">{{ level }}A</span>
							</div>
							<div class="Titleplayback_name">
								{{ description }}
							</div>
						</div>
					</div>
					<div class="Titleplayback-area">
						<div class="area_title">
							景区地址 :
							<span v-if="cityName == provinceName">{{ provinceName }}</span><span
								v-else>{{ provinceName }}-{{ cityName }}</span>
						</div>
					</div>
				</div>
				<audio ref="audioDescript" :src="descriptionAudio" @play="onPlay" @pause="onPause"></audio>
			</div>

			<!-- marker 景点 MarkPointShow -->
			<div class="marker-box" v-if="MarkPointShow">
				<div style="font-size: 0; position: relative; height: 20px">
					<img src="../assets/map/close.png" alt="" class="close_icon_point" @click="onCloseMarkPoint" />
				</div>
				<div class="marker-box-center">
					<div class="marker-box-left">
						<img :src="MarkPointObject.coverImg" alt="" class="Coverimg_icon"
							v-if="MarkPointObject.coverImg" />
						<img src="../assets/map/Coverimg.png" alt="" class="Coverimg_icon" v-else />
					</div>
					<div class="marker-box-right">
						<div class="MarkPoint_name">{{ MarkPointObject.name }}</div>
						<div class="MarkPoint_description" @click="Lookdescription">
							{{ MarkPointObject.description }}
						</div>
						<div class="MarkPoint-plan">
							<div class="horn_box" @click="onPlayCancel">
								<img src="../assets/map/playing.gif" alt="" class="playing_icon" v-if="isPlaying" />
								<img src="../assets/map/suspend.png" alt="" class="suspend_icon" v-else />
								<span class="horn_text">解说</span>
							</div>
							<!-- <div class="planning_box" @click="Routeplanning">
                <img src="../assets/map/planning.png" alt="" class="planning_icon" />
                <span class="horn_text">线路规划</span>
              </div> -->
						</div>
					</div>
				</div>
				<audio ref="audioPlayer" :src="MarkPointObject.audioUrl" @ended="onAudioEnded" autoplay></audio>
			</div>

			<!-- 景点列表 PresentationShow-->
			<div class="presentation-box" v-if="PresentationShow">
				<div class="wrap">
					<div class="header">
						景点展示({{ lengthTotal }})
						<img src="../assets/map/close.png" alt="" class="close_icon" @click="onClosePresentation" />
					</div>
					<div class="main">
						<div v-for="(item, index) in mark" :key="index" class="Trumpet_box">
							<div class="demo_Trumpet">
								<img src="../assets/map/play_active.gif" alt="" class="play_active"
									v-if="currentPlayingId == item.id && isPlaying" />
								<img src="../assets/map/Trumpet.png" alt="" class="Trumpet_icon" v-else />
								<span class="Trumpet_name">{{ item.name }}</span>
								<img src="../assets/map/isVisitm.png" alt="" class="isVisitm_icon"
									v-if="item.isMustVisit == 1" />
							</div>
							<div class="play" @click="MusicPlay(item)">
								<div style="font-size: 0">
									<img src="../assets/map/Earphone.png" alt="" class="Earphone_icon" />
								</div>
								<span class="Earphone-text">播放</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 检测 -->
			<div class="eye_box" @click="onDetection">
				<img src="../assets/Washing/eye.png" alt="" class="eye-icon" />
			</div>

			<!-- 文本展示 -->
			<van-popup v-model="descriptionsShow" round closeable close-icon-position="top-right">
				<div class="description_box">
					<div class="MarkPointObject_popop">{{ MarkPointObject.name }}</div>
					<div class="description_text">{{ MarkPointObject.description }}</div>
				</div>
			</van-popup>
			<div class="footer-box">
				<div class="footer-box-list">
					<div class="footer_demo" @click="onRoute" v-if="notclick">
						<img src="../assets/map/route.png" alt="" class="route-icon" />
						<span class="footer-text" :class="{ 'footer-text_active': CurrentIndex === 0 }">推荐路线</span>
					</div>
					<div class="footer_demo" v-else>
						<img src="../assets/map/route.png" alt="" class="route-icon" />
						<span class="footer-text" :class="{ 'footer-text_active': CurrentIndex === 0 }">推荐路线</span>
					</div>
					<div class="footer_demo" @click="onDetectionAudio(1)">
						<img src="../assets/map/Attractions.png" alt="" class="route-icon" />
						<span class="footer-text" :class="{ 'footer-text_active': CurrentIndex === 1 }">景点列表</span>
					</div>
					<div class="footer_demo" @click="onRouter(2)">
						<img src="../assets/map/spot.png" alt="" class="route-icon" />
						<span class="footer-text" :class="{ 'footer-text_active': CurrentIndex === 2 }">景区服务</span>
					</div>
					<div class="introduce">
						<van-switch :disabled="true" @click="handSwitch" v-model="checked" active-color="#07c160"
							inactive-color="#ACACAC" size="12px" />
						<div class="automatic">自动</div>
					</div>
					<div class="day" @click="handleDay">
						<img v-if="isDay" class="dayTime" src="../assets/map/daytime.png" alt="" />
						<img v-else class="night" src="../assets/map/night.png" alt="" />
						<div v-if="isDay" class="size">白天</div>
						<div v-else class="size">夜晚</div>
					</div>
					<audio autoplay ref="audioPlayer1" :src="audioUrl"></audio>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import "leaflet/dist/leaflet.css";
	import {
		getAction
	} from "../utils/httpRequest.js";
	import L from "leaflet";
	import {
		marker
	} from "leaflet";
	export default {
		data() {
			return {
				isDay: true,
				disabled: true,
				notclick: true,
				checked: false,
				map: null, //初始化 map 对象
				trackPoints: [
					//自行获取数据
				],
				result: "",
				mMap: "",
				gdtbLayerId: 0,
				center: {
					latitude: "", //纬度
					longitude: "", //经度
				},
				scale: 16,
				bkUrl: "",
				bkUrl1: "",
				params: {
					id: "", //当前景点id 【首页-景点列表】接口返回的
				},
				title: "",
				markes: [],
				fanwei: {
					northeast: {
						longitude: 118.800805,
						latitude: 32.04637,
					},
					southwest: {
						longitude: 118.794078,
						latitude: 32.042279,
					},
				},
				AudioUrl: "",
				name: "",
				CoverImg: "",
				AudioCouponShow: false,
				isPlaying: false, // 标识音频播放
				isImage: false,
				loadingkey: false,
				latitude: "",
				longitude: "",
				isRoute: true,
				description: "",
				cityName: "",
				provinceName: "",
				level: "",
				FirstLagShow: false,
				MarkPointObject: {},
				MarkPointShow: false, // 标记点弹窗
				PresentationShow: false,
				lengthTotal: "", //
				PlayFlag: false, // 播放打开 关闭原先的解说
				SwitchFlag: false, // 展示标题的开关
				currentPlayingId: null, // 当前正在播放的景区的id
				PlaybackFlag: false, //控制景区那个播放动画
				isSpinning: false, // 控制旋转的标志
				RouteShow: false,
				descriptionAudio: "", //音频播放
				CurrentIndex: -1,
				overlay1: null,
				isShow: false,
				condition: ["导览", "线路", "路线", "路"],
				descriptionsShow: false, //文本展示框
				publicList: [], //公共的数据
				publicListShow: false,
				publicMarkers: [],
				audioUrl: null,
				markersWap: [],
				distance: null,
				isAudio: true,
				PlaySuspended: false,
				StartSuspended: true,
				timer: null,
				longitudeAbc: null,
				latitudeAbc: null,
				longitudeA: null,
				latitudeA: null,
				bounds: null,
				isInRectangle: false,
				lmarkerAbc: [],
				overNight: "",
				nightlay: null,
				leftflag: true,
				scenicName: ""
			};
		},
		created() {
			let token = this.$route.query.token;
			localStorage.setItem("token", token);
		},
		mounted() {
			// this.params.id = "5ed5a318f50a8a7b0b5ede54afcac17b";
			this.params.id = this.$route.query.scenicId;
			this.latitude = this.$route.query.latitude;
			this.longitude = this.$route.query.longitude;
			this.leftflag = this.$route.query.leftflag;
			this.scenicName = this.$route.query.scenicName;
			if (this.leftflag) {
				this.leftflag = false;
			} else {
				this.leftflag = true;
			}
			// 显示加载动画
			const startTime = new Date().getTime();
			const diff = new Date().getTime() - startTime;
			this.loadingkey = true;
			setTimeout(() => {
				// this.loadingkey = false;
				// 调用异步方法加载数据
			}, Math.max(0, 800 - diff));
			this.getScenicDetail();
			this.onPublic();
			this.startLocationUpdate();
			if (this.isInRectangle) {
				setInterval(() => {
					this.startLocationUpdate1();
				}, 2000);
			}
		},
		watch: {
			// 监听音频播放状态，当音频播放完成时调用onAudioEnded方法
			"$refs.audioPlayer.ended": function(newVal) {
				if (newVal) {
					this.onAudioEnded();
				}
			},
		},
		methods: {
			async getScenicDetail() {
				await getAction(`scenicSpot/queryById?id=${this.params.id}`)
					.then((res) => {
						console.log(res.data.result);
						this.name = res.data.result.name;
						if (res.data.result.drawImg.split(",")[1]) {
							if (
								this.condition.find((item) =>
									res.data.result.drawImg.split(",")[0].includes(item)
								)
							) {
								this.bkUrl = res.data.result.drawImg.split(",")[1];
								this.bkUrl1 = res.data.result.drawImg.split(",")[0];
							} else {
								this.bkUrl = res.data.result.drawImg.split(",")[0];
								this.bkUrl1 = res.data.result.drawImg.split(",")[1];
							}
						} else {
							this.bkUrl = res.data.result.drawImg;
						}
						this.overNight = res.data.result.nightImg;
						this.result = JSON.parse(res.data.result.scope);
						this.fanwei.northeast.longitude = this.result[1][1];
						this.fanwei.northeast.latitude = this.result[1][0];
						this.fanwei.southwest.longitude = this.result[0][1];
						this.fanwei.southwest.latitude = this.result[0][0];
						this.scale = res.data.result.scale;
						this.minScale = res.data.result.zoomScale.split(",")[0];
						this.maxScale = res.data.result.zoomScale.split(",")[1];
						this.center.latitude = res.data.result.glat;
						this.center.longitude = res.data.result.glng;
						this.mark = res.data.result.markers;
						res.data.result.markers.forEach((item) => {
							this.markersWap.push(item);
						});
						this.description = res.data.result.description;
						this.bounds = res.data.result.scope;
						this.cityName = res.data.result.cityName;
						this.provinceName = res.data.result.provinceName;
						this.coverImg = res.data.result.coverImg;
						this.level = res.data.result.level;
						this.lengthTotal = this.mark.length;
						this.descriptionAudio = res.data.result.descriptionAudio;
						this.myinitMap();
						setTimeout(() => {
							this.loadingkey = false;
							this.mark.forEach((item) => {
								if (item.name == this.scenicName) {
									this.map.setView([JSON.parse(item.location)[0], JSON.parse(item
										.location)[1]], 19)
								}
							})
						}, 2500);
					})
					.catch((error) => {
						console.log(error);
					});
			},
			handleDay() {
				if (this.overNight) {
					this.isDay = !this.isDay;
					if (this.isDay == false) {
						this.nightlay.addTo(this.map);
						this.nightlay.setOpacity(1);
					} else {
						this.nightlay.setOpacity(0);
					}
				} else {
					this.$toast("该景区暂未有夜景");
				}
			},
			handSwitch() {
				if (this.isInRectangle) {
					let audio = this.$refs.audioPlayer1;
					if (!this.checked) {
						audio.play();
						this.$toast("您已打开自动播报");
						this.disabled = false;
						this.timer = setInterval(() => {
							this.startLocationUpdate();
						}, 2000);
					} else {
						audio.pause();
						this.$toast("您已关闭自动播报");
						clearInterval(this.timer);
					}
				} else {
					this.startLocationUpdate()
					setTimeout(()=>{
						if (this.longitudeAbc == null && this.latitudeAbc == null) {
							this.$toast("请确保您打开了您的手机定位,并且同意了我们获取您当前的位置")
						} else {
							this.$toast("您当前不在景区");
							this.disabled = true;
							this.checked = false;
						}
					},500)

				}
			},
			getMarkList() {
				this.doMarks();
			},

			async onPublic() {
				const params = await getAction(
					`scenicFacility//noauth/getFacilitiesBySid?sid=${this.params.id}`
				);
				this.publicList = params.data;
			},
			doMarks(ind) {
				const maxMarkersToShow = 40; // 设置初始最大标记点数量
				this.currentMarkersToShow = maxMarkersToShow;
				this.allMarkers = []; // 用于存储所有标记点

				// 先加载所有标记点到内存中
				if (ind != 2) {
					this.mark.forEach((marker) => {
						const latitude = JSON.parse(marker.location)[0];
						const longitude = JSON.parse(marker.location)[1];
						const title = marker.name;
						var customIcon = L.divIcon({
							className: "custom-icon",
							html: `<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
			              <div style="font-size:0">
			                  <img src="https://static.yzb10086.com/trip/banner/img/file_label_20240611144911712_zbvo.png" style="width: 25px; height: 22px;">
			              </div>
			              <d style="color:#000; font-size:10px; background: linear-gradient(to right,rgba(255,255,255,0.2),rgba(255,255,255,1),rgba(255,255,255,0.2)); padding:2px; border-radius:6px; width: 50px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${title}</span>
			          </div>`,
							iconAnchor: [0, 50],
						});
						var lmarker = L.marker([latitude, longitude], {
							id: marker.id,
							icon: customIcon,
						});
						//标记点的点击事件
						lmarker.on("click", (e) => {
							this.FirstLagShow = false;
							this.SwitchFlag = false;
							var clickedMarkerId = e.target.options.id;
							const clickedMarker = this.mark.find(
								(marker) => marker.id === clickedMarkerId
							);
							this.MarkPointObject = clickedMarker;
							if (clickedMarker) {
								const [latitude, longitude] = JSON.parse(clickedMarker.location);
								// 将地图视图定位到点击的标记点坐标并放大显示
								this.map.setView([latitude, longitude], this.maxScale);
								this.MarkPointShow = true; //展示marker对象

								this.$nextTick(() => {
									const audioPlayer = this.$refs.audioPlayer;
									if (audioPlayer) {
										this.isPlaying = true;
										audioPlayer.play(); // 如果需要，手动播放音频
									}
								});
							} else {
								console.log("没有找到与 clickedMarkerId 相匹配的对象");
							}
						});
						// 将标记点存储在数组中
						this.lmarkerAbc.push(lmarker);
						this.allMarkers.push(lmarker);
					});
				}
				if (this.publicListShow) {
					this.allMarkers = this.allMarkers.concat(this.lmarkerAbc);
					// 添加公共设施的标记点
					this.publicList.forEach((publicFacility) => {
						const latitude = publicFacility.lat;
						const longitude = publicFacility.lng;
						const title = publicFacility.name;
						const type = publicFacility.serviceType;
						// 根据不同的 type 值创建不同的图标
						let publicIcon;
						switch (type) {
							case "3":
								publicIcon = L.divIcon({
									className: "custom-icon",
									html: `<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
                                  <div style="font-size:0">
                                      <img src="https://static.yzb10086.com/trip/banner/img/file_Toilet_20240620140647260_6wju.png" style="width: 30px; height: 30px;">
                                  </div>
                                  <span style="color:#000; font-size:10px; background: linear-gradient(to right,rgba(255,255,255,0.2),rgba(255,255,255,1),rgba(255,255,255,0.2)); padding:2px; border-radius:6px; width: 50px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${title}</span>
                              </div>`,
								});
								break;
							case "4":
								publicIcon = L.divIcon({
									className: "custom-icon",
									html: `<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
                                  <div style="font-size:0">
                                      <img src="https://static.yzb10086.com/trip/banner/img/file_%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240620141116_20240620141125403_mqsr.png" style="width: 30px; height: 30px;">
                                  </div>
                                  <span style="color:#000; font-size:10px; background: linear-gradient(to right,rgba(255,255,255,0.2),rgba(255,255,255,1),rgba(255,255,255,0.2)); padding:2px; border-radius:6px; width: 50px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${title}</span>
                              </div>`,
								});
								break;
							case "5":
								publicIcon = L.divIcon({
									className: "custom-icon",
									html: `<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
                                  <div style="font-size:0">
                                      <img src="https://static.yzb10086.com/trip/banner/img/file_%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240620141014_20240620141028264_ucvc.png" style="width: 30px; height: 30px;">
                                  </div>
                                  <span style="color:#000; font-size:10px; background: linear-gradient(to right,rgba(255,255,255,0.2),rgba(255,255,255,1),rgba(255,255,255,0.2)); padding:2px; border-radius:6px; width: 50px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${title}</span>
                              </div>`,
								});
								break;
							case "13":
								publicIcon = L.divIcon({
									className: "custom-icon",
									html: `<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
                                  <div style="font-size:0">
                                      <img src="https://static.yzb10086.com/trip/banner/img/file_%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240620140820_20240620140854988_9j2n.png" style="width: 30px; height: 30px;">
                                  </div>
                                  <span style="color:#000; font-size:10px; background: linear-gradient(to right,rgba(255,255,255,0.2),rgba(255,255,255,1),rgba(255,255,255,0.2)); padding:2px; border-radius:6px; width: 50px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${title}</span>
                              </div>`,
								});
								break;
						}
						var publicMarker = L.marker([latitude, longitude], {
							icon: publicIcon,
						});
						// console.log(publicMarker)
						this.publicMarkers.push(publicMarker);
						// console.log(this.publicMarkers)
						publicMarker.addTo(this.map); // 根据需要添加或删除此行
					});
				} else {
					this.allMarkers = this.allMarkers.concat(this.lmarkerAbc);
					// 移除公共设施的标记点
					// console.log(this.publicMarkers)
					this.publicMarkers.forEach((marker) => {
						this.map.removeLayer(marker);
					});
				}

				// 显示初始的标记点
				this.showMarkers(this.currentMarkersToShow);
				// 监听地图缩放事件
				this.map.on("zoomend", () => {
					let currentZoom = this.map.getZoom();
					// 在适当的缩放级别加载更多的标记点
					if (currentZoom > 16) {
						// 你可以根据实际情况调整这个缩放等级
						this.currentMarkersToShow = this.allMarkers.length; // 显示所有标记点
					} else {
						this.currentMarkersToShow = maxMarkersToShow; // 重置为显示40个标记点
					}
					this.showMarkers(this.currentMarkersToShow);
				});
			},

			showMarkers(count) {
				// 清除地图上的所有标记点
				this.clearMarkers();
				// 添加指定数量的标记点到地图上
				for (let i = 0; i < count && i < this.allMarkers.length; i++) {
					this.allMarkers[i].addTo(this.map);
				}
			},
			clearMarkers() {
				// 移除地图上的所有标记点
				this.allMarkers.forEach((marker) => {
					this.map.removeLayer(marker);
				});
			},
			myinitMap() {
				// 定义图像加载的边界值（即在地图上的大小、位置）
				var imageBounds = [
					[this.fanwei.southwest.latitude, this.fanwei.southwest.longitude],
					[this.fanwei.northeast.latitude, this.fanwei.northeast.longitude],
				]; // 将图片作为自定义图层加载到地图上（类似于Marker）
				if (this.bkUrl) {
					var overlay = L.imageOverlay(this.bkUrl, imageBounds, {
						opacity: 1,
						zIndex: 20,
					});
					this.overlay1 = L.imageOverlay(this.bkUrl1, imageBounds, {
						opacity: 1,
						zIndex: 21,
					});
					this.nightlay = L.imageOverlay(this.overNight, imageBounds, {
						opacity: 1,
						zIndex: 20,
					});
					this.map = L.map("map", {
						center: L.latLng(this.center.latitude, this.center.longitude), // 地图中心
						zoom: this.scale, // 缩放比例
						minZoom: this.minScale, // 设置最小缩放级别
						maxZoom: this.maxScale, // 设置最大缩放级别
						doubleClickZoom: true, // 禁用双击放大
						attributionControl: false, // 移除右下角 Leaflet 标识
					});
					overlay.addTo(this.map); // 添加图片覆盖物到地图
				} else {
					this.map = L.map("map", {
						center: L.latLng(this.center.latitude, this.center.longitude), // 地图中心
						zoom: this.scale, // 缩放比例
						minZoom: this.minScale, // 设置最小缩放级别
						maxZoom: this.maxScale, // 设置最大缩放级别
						doubleClickZoom: true, // 禁用双击放大
						attributionControl: false, // 移除右下角 Leaflet 标识
					});
					L.tileLayer(
						"http://webrd01.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}", {
							maxZoom: 18,
							attribution: "© AutoNavi",
						}
					).addTo(this.map);
				}
				var maxBounds = L.latLngBounds(
					L.latLng(
						this.fanwei.southwest.latitude,
						this.fanwei.southwest.longitude
					),
					L.latLng(
						this.fanwei.northeast.latitude,
						this.fanwei.northeast.longitude
					)
				);
				this.map.setMaxBounds(maxBounds); // 将放大缩小控件从左上方移动到右下方
				//放大缩小的图标
				L.control
					.zoom({
						position: "bottomright",
					})
					.addTo(this.map);
				this.getMarkList(); // 加载标记 // 监听地图移动事件，确保地图在指定边界范围内移动
				this.map.on("mousemove", (e) => {
					var latlng = e.latlng;
				});
			},
			//返回到uniapp
			handleReturn() {
				window.uni.postMessage({
					data: {
						action: "backLogin",
					},
				});
			},
			// 提取小数点之前的部分
			extractIntegerPart(number) {
				return Math.floor(number);
			},
			//检测
			onDetection() {
				if (this.isInRectangle) {
					this.startLocationUpdate1();
				} else {
					this.startLocationUpdate()
					if (this.longitudeAbc == null && this.latitudeAbc == null) {
						this.$toast("请确保您打开了您的手机定位,并且同意了我们获取您当前的位置")
					} else {
						this.$toast("您当前不在景区");
					}
				}
			},

			// 切换播放和暂停
			onPlayCancel() {
				const audioPlayer = this.$refs.audioPlayer;
				const audioPlayer1 = this.$refs.audioPlayer1;
				if (this.isPlaying) {
					audioPlayer.pause();
					audioPlayer1.pause();
					this.updatePlayIcon(false); // 更新播放图标为暂停状态
				} else {
					audioPlayer.play();
					this.updatePlayIcon(true); // 更新播放图标为播放状态
				}
			},
			// 更新播放图标的状态
			updatePlayIcon(isPlaying) {
				this.isPlaying = isPlaying;
			},
			// 当音频播放完成时调用此方法
			onAudioEnded() {
				// this.PlayFlag = false; // 隐藏播放/暂停图标
				this.isPlaying = false; // 设置为未播放状态
				// 这里可以添加其他逻辑，比如重置音频源等
			},
			//关闭播放
			onCloseMarkPoint() {
				const audioPlayer = this.$refs.audioPlayer;
				// 停止音频播放
				if (audioPlayer) {
					audioPlayer.pause();
					audioPlayer.src = ""; // 重置音频源，防止自动播放
				}
				// 关闭弹窗
				this.MarkPointShow = false;
				// this.PlayFlag = false; // 隐藏播放/暂停图标
				this.isPlaying = false; // 设置为未播放状态
			},

			// 景点列表 播放 要跳转到对应的景点
			MusicPlay(newsItem) {
				console.log(newsItem);
				// 首先检查是否是同一个景区正在播放，如果是，则直接返回
				if (this.currentPlayingId === newsItem.id) {
					return;
				}
				this.isPlaying = true;
				// 接下来的逻辑与之前相同
				this.MarkPointObject = newsItem;
				const targetMarker = this.allMarkers.find(
					(marker) => marker.options.id === newsItem.id
				);
				if (targetMarker) {
					this.onMarkerClickSimulation(targetMarker);
					this.currentPlayingId = newsItem.id; // 更新当前正在播放的景区的id
				} else {
					console.log("没有找到对应的marker");
				}
			},
			onMarkerClickSimulation(marker) {
				// 这里模拟了marker的点击事件，实际使用时应直接使用marker的点击事件
				const latitude = marker.getLatLng().lat;
				const longitude = marker.getLatLng().lng;
				const title = marker.options.title; // 假设marker有title属性
				// 将地图视图定位到景点坐标并放大显示
				this.map.setView([latitude, longitude], 20);

				this.MarkPointShow = true; // 展示播放弹窗
			},
			//景点列表
			onDetectionAudio(ind) {
				this.CurrentIndex = ind;
				this.map.setZoom(this.scale);
				this.map.panTo(L.latLng(this.center.latitude, this.center.longitude));
				this.PresentationShow = true;
			},
			onClosePresentation() {
				this.PresentationShow = false;
				this.RouteShow = false;
			},
			//标题转动, 带动画
			FirstLagPlay() {
				this.PlaybackFlag = !this.PlaybackFlag; // 切换播放/暂停状态
				if (this.PlaybackFlag) {
					this.$refs.audioDescript.play(); // 播放音频
				} else {
					this.$refs.audioDescript.pause(); // 暂停音频
				}
			},
			onPlay() {
				this.isSpinning = true; // 音频开始播放时，图片开始旋转
			},
			onPause() {
				this.isSpinning = false; // 音频暂停时，图片停止旋转
			},
			TitleplaybackPlay() {
				this.MarkPointShow = false;
				this.PresentationShow = false;
				// 切换弹窗的显示状态
				this.FirstLagShow = !this.FirstLagShow;
				// 切换箭头的方向
				this.SwitchFlag = this.FirstLagShow;
				if (!this.FirstLagShow) {
					this.PlaybackFlag = false;
					this.isSpinning = false;
				}
			},
			//推荐线路
			onRoute() {
				// this.map.setZoom(this.scale);
				// this.map.panTo(L.latLng(this.center.latitude, this.center.longitude));
				// this.RouteShow = true
				this.notclick = false;
				setTimeout(() => {
					this.notclick = true;
				}, 1000);
				this.overlay1.addTo(this.map);
				if (this.bkUrl1 == "") {
					this.$toast("暂未开放");
				} else {
					this.isShow = !this.isShow;
					if (this.isShow) {
						this.overlay1.setOpacity(1);
					} else {
						this.overlay1.setOpacity(0);
					}
				}
			},
			onRouter(ind) {
				this.CurrentIndex = ind;
				this.publicListShow = !this.publicListShow;
				// console.log(this.publicListShow)
				this.doMarks(ind);
			},

			Routeplanning() {
				this.$toast("暂未开放");
			},
			Lookdescription() {
				this.descriptionsShow = true;
			},
			startLocationUpdate1() {
				if ("geolocation" in navigator) {
					let that = this;
					navigator.geolocation.getCurrentPosition(
						// 成功
						function(position) {
							// position 对象包含当前位置的信息
							//经度
							that.longitudeA = position.coords.longitude;
							// 纬度
							that.latitudeA = position.coords.latitude;
							// console.log("当前经度：" + position.coords.longitude);
							// console.log("当前纬度：" + position.coords.latitude);
						},
						// 失败
						function(error) {
							// 获取位置失败的处理逻辑
							// console.error("获取位置失败：", error.message);
							//经度
							that.longitudeA = null;
							// 纬度
							that.latitudeA = null;
						}
					);
					setTimeout(() => {
						// 定义矩形区域的边界，即矩形的南西角和北东角的经纬度坐标
						var southWest = L.latLng(
							this.fanwei.southwest.latitude,
							this.fanwei.southwest.longitude
						); // 南西角的经纬度坐标
						var northEast = L.latLng(
							this.fanwei.northeast.latitude,
							this.fanwei.northeast.longitude
						); // 北东角的经纬度坐标
						var bounds = L.latLngBounds(southWest, northEast); // 创建矩形区域的 LatLngBounds 对象
						// 获取当前位置的经纬度坐标
						// var currentLatLng = L.latLng(37.549878528559866,121.40391957718968);
						var currentLatLng = L.latLng(that.latitudeA, that.longitudeA);
						// 判断在不在矩形区域内
						this.isInRectangle = bounds.contains(currentLatLng);
					}, 1000);
					var customIcon1 = L.divIcon({
						className: "custom-icon1",
						html: `<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
					              <div style="font-size:0">
					                  <img src="https://static.yzb10086.com/trip/banner/img/file_positioning_20240701095336917_h6g7.png" style="width: 25px; height: 25px;">
					              </div>
					              <span style="color:#000; font-size:10px; background: #fff; padding:2px; border-radius:6px; width: 60px; ">您当前的位置</span>
					          </div>`,
						// iconAnchor: [0, 50],
					});

					var lmarker = L.marker([that.latitudeA, that.longitudeA], {
						icon: customIcon1,
					});
					lmarker.addTo(this.map);
				}
			},
			startLocationUpdate() {
				// 检查浏览器是否支持 Geolocation API
				if ("geolocation" in navigator) {
					// 这里面用不了this，所以把this赋值给that使用
					let that = this;
					// 获取当前位置
					navigator.geolocation.getCurrentPosition(
						// 成功
						function success(position) {
							// position 对象包含当前位置的信息
							//经度
							that.longitudeAbc = position.coords.longitude;
							// 纬度
							that.latitudeAbc = position.coords.latitude;
							// console.log("当前经度：" + that.longitudeAbc);
							// console.log("当前纬度：" + that.latitudeAbc);
						},
						// 失败
						function errror(error)
						{
							// 获取位置失败的处理逻辑
							// console.log("获取位置失败：", error.message);
							//经度
							that.longitudeAbc = null;
							// 纬度
							that.latitudeAbc = null;
						},
					);
					setTimeout(() => {
						// 定义矩形区域的边界，即矩形的南西角和北东角的经纬度坐标
						var southWest = L.latLng(
							this.fanwei.southwest.latitude,
							this.fanwei.southwest.longitude
						); // 南西角的经纬度坐标
						var northEast = L.latLng(
							this.fanwei.northeast.latitude,
							this.fanwei.northeast.longitude
						); // 北东角的经纬度坐标
						var bounds = L.latLngBounds(southWest, northEast); // 创建矩形区域的 LatLngBounds 对象
						// 获取当前位置的经纬度坐标
						// var currentLatLng = L.latLng(37.549878528559866,121.40391957718968);
						var currentLatLng = L.latLng(that.latitudeAbc, that.longitudeAbc);
						// 判断在不在矩形区域内
						if(this.longitudeAbc!=null&&this.latitudeAbc!=null){
							this.isInRectangle = bounds.contains(currentLatLng);
						}else{
							this.$toast("请您先打开定位功能")
						}
					}, 2500);
					if (that.longitudeAbc!=null&&that.latitudeAbc!=null) {
						this.markersWap.forEach((item, index) => {
							// var distance = this.getDistance(that.longitudeAbc, that.latitudeAbc, 121.4766,37.451417);
							var distance = this.getDistance(
								that.longitudeAbc,
								that.latitudeAbc,
								item.location.split(",")[0].split("[")[1],
								item.location.split(",")[1].split("]")[0]
							); // 得到的距离单位是米
							if (distance <= 0.5 && that.isPlaying == false) {
								this.$toast("正在为您自动播报");
								this.audioUrl = item.audioUrl;
								// this.audioUrl="https://static.yzb10086.com/trip/5ed5a318f50a8a7b0b5ede54afcac17b/audio/e06b1d46158a43b35638722303b916be_nanahongmei_vlpsj.mp3"
								let audio = this.$refs.audioPlayer1;
								audio.play();
							} else {
								let audio = this.$refs.audioPlayer1;
								audio.pause();
							}
						});
					}
				}
			},
			stopLocationUpdate() {
				uni.offLocationChange();
			},
			getDistance(lat1, lon1, lat2, lon2) {
				var R = 6371; // 地球半径，单位：千米
				var dLat = this.deg2rad(lat2 - lat1); // 将度数转换为弧度
				var dLon = this.deg2rad(lon2 - lon1);
				var a =
					Math.sin(dLat / 2) * Math.sin(dLat / 2) +
					Math.cos(this.deg2rad(lat1)) *
					Math.cos(this.deg2rad(lat2)) *
					Math.sin(dLon / 2) *
					Math.sin(dLon / 2);
				var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
				var d = R * c; // 距离，单位：千米
				return d;
			},
			deg2rad(deg) {
				return deg * (Math.PI / 180);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.size {
		margin-top: -5px;
	}

	.night,
	.dayTime {
		width: 62px;
		height: 40px;
		margin: 0 -5px;
	}

	.day {
		position: absolute;
		bottom: 420px;
		right: -15px;
		background: #fff;
		padding: 5px 10px 5px 10px;
		border-radius: 10px;
		text-align: center;
		font-size: 24px;
	}

	.introduce {
		position: absolute;
		bottom: 330px;
		right: -15px;
		font-size: 24px;
		background-color: #fff;
		color: #000;
		font-weight: 500;
		border-radius: 10px;
		padding: 5px 10px;
	}

	.playback_box {
		position: absolute;
		// top: 100px;
		background-color: #fff;
		bottom: 150px;
		border-radius: 60px 60px 20px 20px;
	}

	.play_icon {
		width: 110px;
		height: 110px;
	}

	.plays {
		width: 20px;
		height: 24px;
		padding: 6px;
	}

	.closeIcon {
		width: 24px;
		height: 24px;
		padding: 6px;
	}

	.notclick {
		pointer-events: none;
	}

	.description_box {
		width: 700px;

		margin-top: 60px;
		// border: 1px solid red;
	}

	.van-popup::-webkit-scrollbar {
		display: none;
	}

	.MarkPointObject_popop {
		font-family: Source Han Sans CN;
		font-weight: 700;
		font-size: 32px;
		color: #000000;
		line-height: 30px;
		height: 34px;
		text-align: center;
	}

	.description_text {
		font-size: 30px;
		color: #999;
		margin: 30px 40px;
		text-indent: 2em;
	}

	.container-header-icon {
		overflow: hidden;
		background-color: #fff;
		height: 100vh;
	}

	.leaflet_box {
		width: 100%;
		height: 100%;
		// border: 1px solid red;vh
		position: relative;
	}

	.map {
		width: 100vw;
		height: 100vh;
		z-index: 1;
	}

	.header-icon-loading {
		border: 1px solid #000;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	// .loading-icon {
	//   position: absolute;
	//   top: 50%;
	//   left: 50%;
	//   transform: translate(-50%, -50%);
	//   width: 100%;
	//   height: 100%;
	//   object-fit: contain;
	//   z-index: 9;
	//   /* 可选，确保图片不会拉伸变形 */
	// }

	.eye_box {
		position: absolute;
		bottom: 160px;
		left: 10px;
		z-index: 9;
	}

	.eye-icon {
		width: 80px;
		height: 80px;
	}

	.route_icon {
		position: absolute;
		top: 100px;
		left: 10px;
		z-index: 9;
		width: 90px;
		height: 90px;
	}

	::v-deep .leaflet-top {
		.leaflet-control-zoom {
			display: none;
		}
	}

	.header-box-up {
		position: absolute;
		display: flex;
		top: 0;
		z-index: 9;
		margin-top: 80px;
		// border: 1px solid red;
		// align-items: center;
		width: 100%;
		/* 添加这行样式以撑满父容器宽度 */
	}

	.header-box {
		background: white;
		// width: 80px;
		height: 60px;
		border-radius: 0 60px 60px 0;
		font-size: 0;
		display: flex;
		align-items: center;
		// margin-right: 180px;
		/* 垂直居中 */
	}

	.left_icon {
		width: 18px;
		height: 34px;
		padding-left: 28px;
		// border: 1px solid #000;
		padding: 10px 35px 10px 28px;
	}

	.header-box-name {
		display: flex;
		align-items: center;
		background: white;
		padding: 16px 26px;
		border-radius: 50px;
		box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
		/* 垂直居中 */
		// border: 1px solid #000;
		margin-left: 150px;
	}

	.down_icon {
		width: 14px;
		height: 12px;
		margin-left: 10px;
	}

	.Title_box {
		position: absolute;
		display: flex;
		top: 170px;
		z-index: 9;
		width: 100%;
		width: 690px;
		background: #fff;
		margin-left: 30px;
		box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
		border-radius: 20px;
	}

	.Titleplayback_box_main {
		padding: 37px 45px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.Titleplayback {
		display: flex;
		align-items: center;
	}

	.Titleplayback_icon {
		width: 136px;
		height: 136px;
		border-radius: 100px;
		background-color: #f8f8f8;
	}

	.title-name {
		font-size: 34px;
		color: #000;
		font-weight: 700;
	}

	.right-name {
		font-family: Source Han Sans CN;
		font-weight: 500;
		font-size: 34px;
		color: #000000;
		font-weight: 700;
	}

	.right-leve {
		background: #fe7058;
		border-radius: 5px;
		color: #fff;
		font-size: 20px;
		line-height: 28px;
		padding: 5px 8px;
		margin-left: 18px;
	}

	.Titleplayback_right {
		margin-left: 41px;
	}

	.Titleplayback_name {
		font-size: 24px;
		color: #666;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		margin-top: 17px;
	}

	.Titleplayback-area {
		width: 600px;
		background: #f0f0f0;
		border-radius: 10px;
		margin-top: 18px;
	}

	.area_title {
		font-size: 24px;
		color: #666;
		padding: 28px;
	}

	.Titleplayback_left {
		position: relative;
	}

	.start-icon {
		width: 52px;
		height: 52px;
	}

	.map_start_play {
		position: absolute;
		font-size: 0;
		top: 42px;
		left: 42px;
	}

	.isVisitm_icon {
		width: 87px;
		height: 32px;
		margin-left: 10px;
	}

	.footer-box {
		position: absolute;
		bottom: 0;
		// bottom: 80px;
		z-index: 9;
		background: #fff;
		width: 100%;
		height: 98px;
		width: 690px;
		margin-left: 30px;
		border-radius: 30px 30px 0 0;
	}

	.footer-box-list {
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding-top: 30px;
	}

	.route-icon {
		width: 32px;
		height: 32px;
	}

	.footer-text {
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 32px;
		color: #666666;
		margin-left: 13px;
	}

	.footer-text_active {
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 32px;
		color: #fe7058;
		margin-left: 13px;
	}

	.SwitchFlag_demo {
		display: flex;
		align-items: center;
		padding: 0 30px 0 0;
	}

	.footer_demo {
		display: flex;
		align-items: center;
	}

	::v-deep .leaflet-bottom {
		position: absolute;
		bottom: 160px;

		.leaflet-control-zoom-in {
			span {
				font-size: 40px;
				color: rgb(255, 131, 41);
			}
		}

		.leaflet-control-zoom-out {
			span {
				font-size: 40px;
				color: rgb(255, 131, 41);
			}
		}
	}

	::v-deep .leaflet-touch .leaflet-bar a {
		width: 70px;
		height: 70px;
		line-height: 70px;
	}

	.marker-box {
		position: absolute;
		z-index: 6;
		margin-left: 30px;
		border-radius: 20px;
		background: url(../assets/map/pointBack1.png) no-repeat;
		background-size: 100% 100%;
		width: 690px;
		height: 340px;
		top: 32.4%;
		margin-top: -170px;
	}

	.marker-box-center {
		// border: 1px solid #000;
		margin: 12px 50px 28px;
		display: flex;
		align-items: center;
	}

	.Coverimg_icon {
		width: 160px;
		height: 160px;
		border-radius: 20px;
		margin-right: 23px;
	}

	.marker-box-left {
		font-size: 0;
	}

	.MarkPoint_name {
		font-family: Source Han Sans CN;
		font-weight: 700;
		font-size: 32px;
		color: #000000;
		line-height: 22px;
	}

	.MarkPoint_description {
		font-size: 20px;
		color: #666;
		margin-top: 12px;
		overflow: hidden;
		/* 设置最大高度为两行文本的高度 */
		text-overflow: ellipsis;
		/* 溢出时显示省略号 */
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		/* 限制在两行内 */
	}

	.horn_box {
		width: 130px;
		height: 43px;
		background: #ff8329;
		border-radius: 10px;
		display: flex;
		align-items: center;
		margin-top: 12px;
	}

	.horn_icon {
		width: 28px;
		height: 24px;
		margin-left: 26px;
		margin-right: 11px;
	}

	.suspend_icon {
		width: 19px;
		height: 24px;
		margin-left: 26px;
		margin-right: 11px;
	}

	.horn_text {
		height: 19px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 20px;
		color: #ffffff;
		line-height: 22px;
	}

	.presentation-box {
		position: absolute;
		bottom: 0;
		z-index: 12;
		width: 100%;
		background-color: #fff;
		height: 640px;
		border-radius: 30px 30px 0 0;
	}

	.wrap {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 640px;
	}

	.header {
		height: 93px;
		line-height: 93px;
		text-align: center;
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 28px;
		color: #000000;
		border-bottom: 1px solid #eee;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 268px;
	}

	.main {
		flex: 1;
		width: 100%;
		height: 100%;
		overflow-y: auto;
	}

	.close_icon {
		width: 24px;
		height: 24px;
		padding: 8px;
		margin-right: 30px;
	}

	.Trumpet_icon {
		width: 29px;
		height: 25px;
	}

	.Trumpet_name {
		height: 26px;
		font-family: PingFang SC;
		font-weight: 500;
		font-size: 28px;
		color: #000000;
		margin-left: 15px;
	}

	.Trumpet_box {
		margin: 35px 26px 38px 38px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.demo_Trumpet {
		font-size: 0;
	}

	.play {
		width: 100px;
		height: 36px;
		background: #ff7910;
		border-radius: 15px;
		// text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.Earphone_icon {
		width: 20px;
		height: 16px;
	}

	.Earphone-text {
		height: 19px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 20px;
		color: #ffffff;
		padding-left: 5px;
		padding-bottom: 10px;
	}

	.play_active {
		width: 26px;
		height: 16px;
	}

	.main::-webkit-scrollbar {
		display: none;
	}

	.MarkPoint-plan {
		display: flex;
		align-items: center;
	}

	.planning_icon {
		width: 24px;
		height: 24px;
		margin: 0 10px 0 28px;
	}

	.planning_box {
		width: 190px;
		height: 43px;
		background: #29acff;
		border-radius: 10px;
		text-align: center;
		display: flex;
		align-items: center;
		margin-top: 12px;
		margin-left: 40px;
	}

	.close_icon_point {
		width: 24px;
		height: 24px;
		padding: 4px;
		text-align: right;
		position: absolute;
		right: 40px;
		top: 20px;
	}

	.playing_icon {
		width: 28px;
		height: 28px;
		margin-left: 26px;
		margin-right: 11px;
	}

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}

	/* 初始状态不旋转 */
	.spin {
		animation: none;
	}

	/* 旋转状态 */
	.spin-active {
		animation: spin 2s linear infinite;
	}

	.Route_box_up {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 34px;
	}

	.Route_box {
		margin: 35px 45px 0 38px;
		// border: 1px solid #000;
	}

	.Panoramic_left {
		height: 26px;
		font-family: PingFang SC;
		font-weight: 700;
		font-size: 30px;
		color: #000000;
		line-height: 26px;
	}

	.Panoramic_right {
		width: 120px;
		height: 40px;
		background: #ff7910;
		border-radius: 15px;
		color: #fff;
		font-size: 20px;
		line-height: 42px;
		text-align: center;
	}

	.mountain_icon {
		width: 32px;
		height: 28px;
	}

	.mountain_text {
		height: 28px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 26px;
		color: #000000;
		line-height: 28px;
		margin-left: 12px;
	}

	.demo_mountain {
		display: flex;
		align-items: center;
		margin-right: 34px;
	}

	.static_icon {
		width: 29px;
		height: 28px;
	}

	.mountain_box {
		display: flex;
		align-items: center;
	}
</style>