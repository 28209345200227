<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view />
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import { wxConfig } from "./views/wxConfig";
export default {
  methods: {
    registerWxConfig() {
      console.log("registerWxConfig");
      if (this.isWeixin()) {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            console.log("在小程序内");
          } else {
            console.log("不在小程序内");
            wxConfig();
          }
        });
      }
    },
    isWeixin() {
      return /micromessenger/i.test(window.navigator.userAgent);
    },
  },
  mounted() {
    // this.registerWxConfig();
  },
};
</script>
<style lang="scss">
// @import '~reset-css/sass/_reset.scss'

// 全局样式
body::-webkit-scrollbar {
  display: none;
}

#app {
  max-width: 100%;
  overflow-x: hidden;
}

// vant样式全局覆盖
#app .van-field--error .van-field__control::placeholder {
  color: #c8c9cc;
}

@media screen and (min-width: 992px) {
  html {
    font-size: 75px !important; /* 大写P防止被转换 */
  }
  #app {
    width: 750px;
    margin: 0 auto;
  }
}
</style>
